import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Table,
  Row,
  Col,
  Card,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Container,
} from "reactstrap";
import PerformanceManagement from "../Api/PerformanceManagement";
import "../CSS/selfAssessment.css";
import { DefaultHeaders, getLogin } from "../Api/api";
import { useSelector } from "react-redux";
import { getList, postData } from "../Api/commonApi";
import Toast, { handleSuccessToast, handleErrorToast } from "./Toast";
import { putReq, postReq, getReq, deleteReq, fileReq } from "../Api/api";
import Select from "react-select";
import StepIndicator from "./StepIndicator";

function SetGoals() {
  let url = process.env.REACT_APP_URL;
  let select = useSelector((state) => state.login?.tasks);


  const [GoalData, setGoalData] = useState(null);
  const [addGoalModal, setAddGoalModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const goalDimensions = [
    { value: "Organization", label: "Organization" },
    { value: "People", label: "People" },
    { value: "Customer", label: "Customer" },
    { value: "Work", label: "Work" },
    { value: "Self", label: "Self" },
  ];
  const [goalDimensionValue, setGoalDimensionValue] = useState("");
  const [goalsArray, setgoalsArray] = useState([]);
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false); 
  const [selectedDimension, setSelectedDimension] = useState(null);
  const [dimensionModal, setDimensionModal] = useState(false);
  const [financialYear, setFinancialYear] = useState('');
  useEffect(() => {
    getGoalsByuserId();
    const currentYear = new Date().getFullYear();
    setFinancialYear(`${currentYear}`);
  }, []);

  useEffect(() => {
    if (selectedDimension) {
      getReq(`${url}goalAssessment/get-all-rows/${selectedDimension.value}`)
        .then((response) => {
        })
        .catch((error) => {
        });
    }
  }, [selectedDimension]);

  useEffect(() => {
    getGoalsByuserId();
  }, []);

  useEffect(() => {
   
    if (GoalData?.status === "Initialized") {
      setEditForm(true);
      // setshowEscalation("employee_initialized");
    }
    if (GoalData?.status === "saved") {
      console.log('inside goal master')
      let goalMasterId = GoalData?.id;
      getReq(`${url}goalAssessment/get-all-rows/${goalMasterId}`).then(
        (response) => {
          const splitquest = response?.data?.form
          setgoalsArray(splitquest || []);
          // setMessage(response?.data?.message)
        }
      );
      setEditForm(true);
      // setshowEscalation("employee_initialized");
    }
    if (GoalData?.status === "EmployeeSubmitted" ||GoalData?.status === "ManagerSaved") {
      let goalMasterId = GoalData?.id;
      getReq(`${url}goalAssessment/get-all-rows/${goalMasterId}`).then(
        (response) => {
          setgoalsArray(response?.data?.form);  
        }
      );
      setDisabled(true);
    }

    if (GoalData?.status === "ManagerApproved") {
      let goalMasterId = GoalData?.id;
      getReq(`${url}goalAssessment/get-all-rows/${goalMasterId}`).then(
        (response) => {
          if (response?.data?.form?.length >= 1) {
            const splitquest = response?.data?.form
            setgoalsArray(splitquest || []);
         
          }
        }
      );
      setDisabled(true);
    }

    if (GoalData?.status === "Closed") {
      let goalMasterId = GoalData?.id;
      getReq(`${url}goalAssessment/get-all-rows/${goalMasterId}`).then(
        (response) => {
          if (response?.data?.form?.length >= 1) {
            const splitquest = response?.data?.form          
            setgoalsArray(splitquest || []);         
          }
        }
      );
      setEditForm(false);
      // setshowEscalation("Closed");
      // setEmployeeEscalated(true);
      setDisabled(true);
    }
    if (GoalData?.status === "HRSubmitted") {
      let goalMasterId = GoalData?.id;
      getReq(`${url}goalAssessment/get-all-rows/${goalMasterId}`).then(
        (response) => {
          if (response?.data?.form?.length >= 1) {
            const splitquest = response?.data?.form
           
            setgoalsArray(splitquest || []);
          }
        }
      );
      setEditForm(false);
      // setshowEscalation("hr_comments_submitted");
      setDisabled(true);
      // setHrState(true);
    }
    if (GoalData?.status === "EmployeeEscalated") {
      let goalMasterId = GoalData?.id;
      getReq(`${url}goalAssessment/get-all-rows/${goalMasterId}`).then(
        (response) => {
          if (response.data?.form?.length >= 1) {
            const splitquest = response?.data?.form
          
            setgoalsArray(splitquest || []);
          }
        }
      );
      // setshowEscalation("employee_escalation");
      // setEmployeeEscalated(true);
      setDisabled(true);
    }
  }, [GoalData]);

  const getGoalsByuserId = async () => {
    let data = await getList(`${url}goalAssessment/getStatusByUserID/${select[0].id}`);
    console.log(select[0])
    // setGoal_initiated(data?.message);
    setGoalData(data?.goalMaster || null);
  };

  const handleAddGoalOpen = () => {
    setAddGoalModal(true);
  }

  const handleAddGoalClose = () => {
    setAddGoalModal(false);
    setGoalDimensionValue("");
  }

  const handleAddGoal = () => {
    setEditForm(true);
    if (goalsArray?.length >= 0) {
      setgoalsArray((prevGoalsArray) => [
        ...prevGoalsArray,
        {
          goalId: goalsArray?.length + 1,
          goal: "",
          dimension: goalDimensionValue,
          weightage: null
        },
      ]);
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth", 
      });
    }
    setAddGoalModal(false);
  };

  const handleSelectChange = (event, goalIndex, field, additionalfield) => {
    const value = event.target.value;
    if (parseFloat(value) <= 0) {
      handleErrorToast("Zero and Negative values are not allowed!");
      return;
    }

    setgoalsArray((prevGoalsArray) => {
      const newGoalsArray = [...prevGoalsArray];
      if (field === "weightage"&& (parseFloat(value) <= 0 || parseFloat(value) > 100)) {
        newGoalsArray[goalIndex][field] = parseInt(value, 10);
      }
      newGoalsArray[goalIndex][field] = value;
      return newGoalsArray;
    });
  };

  const deleteQuestionById = async (goalId) => {
    try {
      const response = await deleteReq(
        `${url}goalAssessment/deleteById/${goalId}`
      );
      if (response.status === 200) {
        handleSuccessToast(`${goalId} has been deleted successfully!`);
        // Handle any additional logic or state updates if needed
      } else {
        if (!goalId) {
          handleSuccessToast('Row deleted successfully!');
        } else {
          handleErrorToast(`Error deleting ${goalId}, please try again!`);
        }
        // Handle error or show a notification to the user
      }
    } catch (error) {
      console.error("Error deleting goal:", error);
      // Handle error or show a notification to the user
    }
  };

  const handleDeleteRow = async (goalIndex, goalId) => {
    await deleteQuestionById(goalId);
    const del = goalsArray?.filter((goal, index) => index != goalIndex)
    setgoalsArray(del)
  };

  const handleEdit = () => {
    setEditForm(!editForm);
  };

  const handleSave = () => {

    let goals = goalsArray?.map((obj, index) => {
      obj.goalMasterId = GoalData?.id;
      return obj;
    });
    const goalsArrayData = goals?.filter((goal, index) => (goal.goal || goal.employeeComments || goal.employeeRating || goal.dimension))
    // Make API call to save the entire questionsArray
    postReq(`${url}goalAssessment/submit`, JSON.stringify(goalsArrayData))
      .then((data) => {
        handleSuccessToast("Data has been saved successfully!");

        getReq(`${url}goalAssessment/get-all-rows/${GoalData?.id}`).then(
          (response) => {
            // const splitquest = response?.data?.form
            setgoalsArray(response?.data?.form || []);
          }
        );
      })
      .catch((error) => {
        handleErrorToast("Data has not been saved!");
      });

    putReq(`${url}goalAssessment/updateStatus/${GoalData?.id}/saved`)
      .then((data) => {
      })
      .catch((error) => {
        console.log(error);
      });
    setEditForm(false);
  };

  const handleSubmit = () => {
   
    let disabled = goalsArray?.filter(
      (obj) => !obj.goal?.trim()
    );

    let weightageCheck = goalsArray?.filter(
      (obj) => !obj.weightage
    );
    const selectedDimensions =[... new Set(goalsArray.map((obj) => obj.dimension))];
    const totalWeightage = goalsArray?.reduce(
      (sum, question) => {
        console.log("Question Weightage:", question.weightage);
        return sum + parseFloat(question.weightage, 10);
      },
      0
    );///need to be updated
    console.log("Total Weightage:", totalWeightage);


    if (disabled?.length > 0 || weightageCheck?.length != 0) {
      handleErrorToast(
        "Please add Goals and give Weightage to all the goals!"
      );
    }else if (totalWeightage !==100) {
      handleErrorToast(
        "Please Check the total weightage is 100!"
      );
    }
    else if (selectedDimensions?.length!=5) {
      handleErrorToast("Please select at least one goal from each dimension.");
    }
    else if (goalsArray.some(question => parseInt(question.weightage, 10) <= 0)) {
      handleErrorToast("Zero and negative values are not allowed for weightage!");
    } 
    // else if(totalWeightage !== 100) {
    //   handleErrorToast("Please adjust the weightage to be exactly 100.");
    // }
    // else if (questionsArray.map(question => question.weightage) <= 1) {
    //   handleErrorToast("Zero and Negative values are not allowed!");
    // }
    else {
      // console.log(questionsArray)
      //   Calculate the total weightage
      const apiEndpoint = `${url}goalAssessment/submit`;
      let goalMasterId = GoalData?.id;
      let goalsArrayData = goalsArray?.map((obj, index) => {
        obj.goalMasterId = GoalData?.id;
        return obj;
        // Ensure additionalComments is an object
      });
      // console.log(questionsArrayData, '----------------------');
    
      // Make API call to save the entire questionsArray
      postReq(apiEndpoint, JSON.stringify(goalsArrayData))
        .then((data) => {
          handleSuccessToast("Data has been submitted successfully!");
          getGoalsByuserId();
          setDisabled(true);
        })
        .catch((error) => {
          handleErrorToast("Data has not been saved!");
        });

      putReq(`${url}goalAssessment/updateStatus/${goalMasterId}/EmployeeSubmitted`)
        .then((data) => {
          setEditForm(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDimensionClick = (dimension) => {
    setSelectedDimension(dimension);
    setDimensionModal(true);
  };

  return (
    <>
      {!GoalData ? (
        <Container className="teams-container" xs="3" md="8">
          <Table className="mt-4">
            <tbody>
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "#f2f2f2",
                      borderRadius: "5px",
                    }}
                  >
                    <h4>Goals Not Yet Initiated</h4>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      ) : (
        <div className="container self_assessment_container py-3">
          <div className="d-flex justify-content-between pb-2">
            <div>
              <StepIndicator data={GoalData?.status} />
            </div>
            <div className="goal_button">
              <Button
                className="fw-semibold"
                onClick={handleAddGoalOpen}
                disabled={disabled}
              >
                <i class="bi bi-plus-lg"></i> Add a Goal
              </Button>
            </div>
            {addGoalModal && (
              <Modal isOpen={addGoalModal} centered>
                <ModalHeader toggle={handleAddGoalClose}>
                  Add a Goal
                </ModalHeader>
                <ModalBody>
                  <Row className="d-flex">
                    <h5>Dimension: </h5>
                    <Select
                      required
                      defaultValue={{
                        value: "Select Category",
                        label: "Select Category",
                      }}
                      options={goalDimensions}
                      onChange={(selectedOption) =>
                        setGoalDimensionValue(selectedOption.value)
                      }
                    />
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleAddGoal} 
                  disabled={goalDimensionValue.trim() === ""}>
                    Submit
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </div>
          <div className="self_assessment_container_content">
            <div>
              <div className="card card_content">
                <div className="card-body">
                  <div className="row">
                    <div>
                      <p className="card_heading fw-semibold">
                        Guidelines on how to complete Goal Setting form.
                      </p>
                    </div>
                    <div>
                      <p className="card_details">
                        Please list all your goals and achievements for
                        the current appraisal period . Make sure the total weightage is 100%.
                      </p>
                      <p className="card_details">
                      <b>Financial Year: {financialYear} </b>
                     </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {editForm === false ? (
              <>
                <div className="col-md-12 appraisal_table">
                  <table className="table self_assessment_table">
                    <thead>
                      <tr>
                        <th className="table_heading" style={{ width: "15%" }}>S No.</th>
                        <th className="table_heading" style={{ width: "25%" }}>Dimension</th>
                        <th className="table_heading" style={{ width: "35%" }}> Goals </th>
                        <th className="table_heading" style={{ width: "25%" }}>Weightage</th>
                      </tr>
                    </thead>
                    <tbody className="table_body">
                      {goalsArray?.map((goalItem, index) => (
                        <tr key={goalItem.goalId}>
                          <td className="text_td">{index + 1}</td>
                          <td className="text_td" 
                          onClick={() => handleDimensionClick(goalItem.dimension)}
                           style={{ cursor: "pointer" }}> 
                             {goalItem.dimension}
                          </td>
                          <td className="text_td">{goalItem.goal}</td>
                          <td className="text_td">{goalItem?.weightage} </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='form_buttons p-3 px-4' style={{ textAlign: 'right' }}>
									<Button className='fw-semibold mx-2'
                  color='secondary' 
                  disabled={disabled} 
                  onClick={handleEdit}>
                    Edit
                    </Button>
								
									<Button 
                  className='fw-semibold' 
                  color='success' onClick={handleSubmit} 
                  disabled={disabled} 
                  id="save_btn">
                    Submit
                    </Button>
								</div>

                </div>
                {addGoalModal && (
              <Modal isOpen={addGoalModal} centered>
                <ModalHeader toggle={handleAddGoalClose}>
                  Add a Goal
                </ModalHeader>
                <ModalBody>
                  <Row className="d-flex">
                    <h5>Dimension: </h5>
                    <Select
                      required
                      defaultValue={{
                        value: "Select Category",
                        label: "Select Category",
                      }}
                      options={goalDimensions}
                      onChange={(selectedOption) =>
                        setGoalDimensionValue(selectedOption.value)
                      }
                    />
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleAddGoal} disabled={goalDimensionValue.trim() === ""}>
                    Submit
                  </Button>
                </ModalFooter>
              </Modal>
            )}            
      {dimensionModal && (
        <Modal isOpen={dimensionModal} toggle={() => setDimensionModal(false)}>
          <ModalHeader toggle={() => setDimensionModal(false)}>
            {selectedDimension}
          </ModalHeader>
          <ModalBody>
            
          </ModalBody>
        </Modal>
      )}
              </>
            ) : (
              <>
                <div className="col-md-12 appraisal_table">
                  <table className="table self_assessment_table">
                    <thead>
                      <tr>
                        <th className="table_heading" style={{ width: "25%" }}>Dimension</th>
                        <th className="table_heading" style={{ width: "52%" }}>Goals</th>
                        <th className="table_heading" style={{ width: "15%" }}>Weightage</th>
                        <th className="table_heading" style={{ width: "8%" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table_body">
                      {goalsArray?.map((goalItem, index) => (
                        <tr>
                          <td>
                            <Input
                              disabled="true"
                              name="dimension"
                              type="text"
                              value={goalItem?.dimension}
                              onChange={(event) =>
                                handleSelectChange(event, index, "dimension")
                              }
                            ></Input>
                          </td>
                          <td>
                            <Input
                              name="text"
                              type="textarea"
                              value={goalItem?.goal}
                              onChange={(event) =>
                                handleSelectChange(event, index, "goal")
                              }
                              rows={10}
                            ></Input>
                          </td>
                          <td>
                            <Input
                              name="weightage"
                              type="number"
                              value={goalItem?.weightage}
                              onChange={(event) =>
                                handleSelectChange(event, index, "weightage")
                              }
                            ></Input>
                          </td>
                          <td style={{ textAlign: "left" }}>
                            <i
                              className={`bi bi-trash3 trash_icon `}
                              onClick={() =>
                                handleDeleteRow(index, goalItem.goalId)
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="form_buttons p-3 px-4" style={{ textAlign: "right" }} >
                    <Button
                      disabled={goalsArray.length === 0 || disabled}
                      className="fw-semibold mx-2"
                      id="edit_save_btn"
                      color="secondary"
                      onClick={(handleEdit, handleSave)}
                    >
                      Save
                    </Button>
                    <Button
                      className="fw-semibold"
                      color="success"
                      onClick={handleSubmit}
                      disabled={goalsArray.length === 0 || disabled}
                    >
                      Submit
                    </Button>
                  </div>

                </div>
              </>
            )}
            <Toast
              setSuccessToast={setSuccessToast}
              setErrorToast={setErrorToast}
            />
          </div>
        </div>
      )
      }
    </>

  );
}

export default SetGoals;