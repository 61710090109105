import PerformanceManagement from "../Api/PerformanceManagement";
import { getList } from "../Api/commonApi";
import React, { useEffect, useState, useRef } from "react";
import "../CSS/Employee_Appraisal_History.css";
import { Table, Button, Row, Input, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getReq, postReq, putReq } from "../Api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Toast, { handleSuccessToast, handleErrorToast } from "./Toast";
import StepIndicator from "./StepIndicator";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa6";
import { FiArrowLeftCircle } from "react-icons/fi";

  
function Employee_Goal_History() {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState()
  const toggle = () => setModal(!modal);
  const handleModal = (type, q) => {
    if (type == "employeeComments")
      setModalData({
        header: q.dimension,
        body: q.employeeComments
      })
    else if (type == "question")
      setModalData({
        header: q.dimension,
        body: q.question
      })
    setModal(!modal)
  }

  const location = useLocation();
  let url = process.env.REACT_APP_URL;
  let id = location?.state?.id;
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [empid, setempid] = useState("");
  const [role, setrole] = useState("");
  const [bussunit, setbuss] = useState("");
  const [L1manager, setL1manager] = useState("");
  const [L2manager, setL2manager] = useState("");
  const [data, setdata] = useState();
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [goalsArray, setGoalsArray] = useState([]);
  const [showForm, setshowForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(
    Array(goalsArray?.length).fill(null)
  );
  // const [editForm, setEditForm] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [check, setCheck] = useState(false);
  const [l1rating, setrating1] = useState()
  const [emprating, setemprating] = useState()
  const [finalRating, setfinalRating] = useState()
  const [len, setLen] = useState();
  const [approvalStatus, setApprovalStatus] = useState(null);
 
  const setrating = (goalArray) => {
    const overallManagerRating = goalArray?.reduce((sum, question) => {
      return (sum + (0.01 * question.weightage * question.managerRating));
    }, 0);

    setrating1(overallManagerRating?.toFixed(2));
  };

  const setemprating1 = (goalArray) => {
    const overallEmployeeRating = goalArray?.reduce((sum, question) => {
      return (sum + (0.01 * question.weightage * question.employeeRating));
    }, 0);
    setemprating(overallEmployeeRating?.toFixed(2));
  };

  const setfinalRating3 = () => {
    setfinalRating(l1rating);
  }

  const handleSelectChange = (event, goalIndex, field, additionalfield) => {
    const value = event.target.value;
    setGoalsArray((prevGoalsArray) => {
      const newGoalsArray = [...prevGoalsArray];
      if (field == "weightage") {
        newGoalsArray[goalIndex][field] = value
      }
      if (field === "managerRating" || field === "employeeRating") {
        newGoalsArray[goalIndex][field] = parseInt(value, 10);
        return newGoalsArray;
      }
      if (field == "additionalComments") {
        if (!newGoalsArray[goalIndex][field]) {
          newGoalsArray[goalIndex][field] = { employee: "", manager: "" };
        }
        newGoalsArray[goalIndex][field][additionalfield] = value;
        return newGoalsArray
      }
      newGoalsArray[goalIndex][field] = value;
      return newGoalsArray;
    });
  };

  const fetchdata = async () => {
    await getReq(`${url}login/getby?id=${id}`)
      .then((response) => {
        console.log(response?.data);
        setname(response?.data?.name);
        setempid(response?.data?.employeeId);
        setrole(response?.data?.department);
        setbuss(response?.data?.bussinessunit);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateBeforeApproval = () => {
    if (!check) {
      handleErrorToast("Please acknowledge by clicking the box.");
      return false;
    }
    return true; // Return true if all validations pass
  };
  const handleApproval = async (updateStatus) => {
    const isValid = validateBeforeApproval();
    if (!isValid) {
      return; // Exit if validation fails
    }
    try {
      const response = await putReq(`${url}goalAssessment/updateStatus/${goalData?.id}/${updateStatus}`);
      if (response?.data?.message=="success") {
        setApprovalStatus(updateStatus);
        setSuccessToast(true);
        getGoalsByuserId();
        setDisabled(true);
        setCheck(false);
      } else {
        setErrorToast(true);
      }
    } catch (error) {
      console.log(error);
      setErrorToast(true);
      setDisabled(true);
    }
  };

  const l1manager = async () => {
    await getReq(`${url}managers?Id=${id}`)
      .then((response) => {
        setL1manager(response.data[0]);
        // console.log(response.  data.name)
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const l2manager = async () => {
    await getReq(`${url}l2managers?Id=${id}`)
      .then((response) => {
        setL2manager(response?.data[0]);
        // console.log(response.  data.name)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGoalMasterId = async () => {
    try {
      const response = await getReq(
        `${url}goalhistroy/getGoalMasterId/${id}`
      );
      return response.data; // Return the master ID
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const [goalData, setGoalData] = useState(null);
  useEffect(() => {

    if (
      goalData?.status == "EmployeeSubmitted" ||
      goalData?.status == "ManagerApproved"
    ) {
      getReq(`${url}goalAssessment/get-all-rows/${goalData?.id}`).then(
        (response) => {
          console.log(response)
          if (response?.data && response.data.form) {
            const splitquest = response.data.form;
            setGoalsArray(splitquest);
          }
          setemprating1(response?.data?.form);
          setfinalRating3();
          setrating(response?.data?.form);
          setemprating1(response?.data?.form);
          setfinalRating3();
          setrating(response?.data?.form);
          setshowForm(true);
          setDisabled(false);
        }
      );
    }

    if (
      goalData?.status == "ManagerApproved" ||
      goalData?.status == "EmployeeEscalated" ||
      goalData?.status == "Closed"
    ) {
      setshowForm(true);
      setDisabled(true);
      let goalMasterId = goalData?.id;
      getReq(`${url}goalAssessment/get-all-rows/${goalMasterId}`).then(
        (response) => {
          if (response?.data && response.data.form) {
            const splitquest = response.data.form;
            setGoalsArray(splitquest);
          }
          setemprating1(response?.data?.form);
          setfinalRating3();
          setrating(response?.data?.form);
        }
      );
    } else {
    }
  }, [goalData]);

  useEffect(() => {
    fetchdata();
    l1manager();
    l2manager();
    getGoalsByuserId();
  }, []);

  const getGoalsByuserId = async () => {
    let data = await getList(`${url}goalAssessment/getStatusByUserID/${id}`);
    if (data) {
      setGoalData(data?.goalMaster);
      console.log(data)
    }
  };

  return (
    <>
      <div className="container main-content self_assessment_container">
        <button className="back-button" 
          onClick={() => { navigate('/team') }}>
          <FiArrowLeftCircle />
          <span>Back</span>
        </button>
        <div className="pl-2">
          <StepIndicator data={goalData?.status} />
        </div>
        {showForm === true ? (
          <div className="app_history_container_content">
            <div>
              <div className="card card_content">
                <div className="card-body">
                  <div className="goal_status">
                    <div>
                      <span className="card-title">
                        Goal Status:{" "}
                        <span className="goal_status">
                          {data && data[0]?.status}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div class="profile-info">
                        <div class="profile-icon">
                          <svg
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                           >
                            <circle cx="25" cy="25" r="25" fill="#FE4D93" />
                            <text
                              x="50%"
                              y="50%"
                              textAnchor="middle"
                              fill="white"
                             >
                              {name
                                ?.split(" ")
                                .map((part) => part.charAt(0))
                                .join("")}
                            </text>
                          </svg>
                        </div>
                        <div class="profile-details">
                          <p className="Profile_name">{name}</p>
                          <p className="profile_silid"> {empid}</p>
                          <p> {role}</p>
                          <p> Business Unit:{bussunit}</p>
                        </div>
                        <div class="vl"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="col-md-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="table_heading">S No.</th>
                      <th className="table_heading" style={{ textAlign: "left" }}>Dimension</th>
                      <th className="table_heading" style={{ width: "20%", textAlign: 'left' }}  > Goals </th>
                      <th className="table_heading" style={{ width: "15%", textAlign: 'left'}}>Weightage</th>

                    </tr>
                  </thead>
                  <tbody className="table_body">
                    {goalsArray?.map((goalItem, index) => (
                      <tr key={goalItem?.goalId}>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "left" }}>
                          {goalItem?.dimension === "work" ? "Work(Apr2023-Dec2023)" : goalItem?.dimension}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {goalItem?.goal}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {goalItem?.weightage}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  className="form_buttons p-3 px-4"
                  style={{ textAlign: "right" }}>
                </div>
              </div>
            </div>

            <div className="">
              <div className="col-md-12">
                <div style={{ border: "0.1rem solid", padding: "1% 1%", margin: '2% 2%' }}>
                  <Row className="mt-2">
                    <Col
                      style={{ paddingLeft: "4%" }}
                      xs={{
                        size: 1,
                      }}
                     >
                      {check ? (
                        <FaUserCheck
                          onClick={() => setCheck(false)}
                          size={20}
                          color="green"
                        />
                       ) : (
                        <MdCheckBoxOutlineBlank
                          onClick={() => setCheck(true)}
                          size={20}
                        />
                       )}
                    </Col>
                    <Col xs={11} className="d-flex align-items-center">
                      <span>
                        <h6>
                          I hereby acknowledge that I have personally met with my
                          employee before providing dimensions and goals on
                          their performance goal appraisal.
                        </h6>
                      </span>
                    </Col>
                  </Row>
                  {!check && (
                    <div style={{ color: '#005dff52', fontSize: '14px', marginTop: '5px' }}>
                      {/* Please acknowledge by checking the box. */}
                    </div>
                  )}
                </div>
                <Col
                  className="form_buttons p-3 px-4"
                  style={{ textAlign: "right" }}
                 >
                </Col>
                <div className="container main-content self_assessment_container">
                  <div className="form_buttons p-3 px-4" style={{ textAlign: "right" }}>
                    {goalData?.status === "EmployeeSubmitted" && (
                      <>
                        <Button
                          color="secondary"
                          onClick={() => handleApproval("ManagerApproved")}
                          disabled={disabled}>
                          Approve
                        </Button>
                        <Button
                          color="success"
                          onClick={() => handleApproval("saved")}
                          disabled={disabled}>
                          Reject
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Modal centered isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>{modalData?.header}</ModalHeader>
              <ModalBody>
                {modalData?.body}
              </ModalBody>
            </Modal>
          </div>
         ) : (
          <Table>
            <thead>
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "#f2f2f2",
                      borderRadius: "5px",
                    }} 
                   >
                    <h4>Employee Yet To Submit His Goals</h4>
                  </div>
                </td>
              </tr>
            </thead>
          </Table>
        )}
        <Toast
          setSuccessToast={setSuccessToast}
          setErrorToast={setErrorToast}
        />
      </div>
    </>
  );
}

export default Employee_Goal_History;
