import React, { useState, useEffect } from 'react';
import PerformanceManagement from "../Api/PerformanceManagement";
import axios from 'axios';
import { useSelector } from "react-redux";
import "../CSS/selfAssessment.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { getList } from "../Api/commonApi";
import {Button, } from "reactstrap";
import { getReq, } from "../Api/api";
import CustomAccordion from "./Accordion";
import props from "./CommunicationHistory";


function MyApprisialHistory() {

  const [appraisals, setAppraisals] = useState([]);
  const [initializedAppraisalList, setInitializedAppraisalList] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  let url = process.env.REACT_APP_URL;
  let id = location?.state?.id;
  let select = useSelector((state) => state.login?.tasks);
  const employeeId = select[0].EmpId;

  useEffect(() => {
    console.log(select[0])
    if (employeeId) {
      getReq(`${url}appraisal/getAppraisalsBy/${employeeId}`)
        .then(response => {
          console.log(response?.data)
          setAppraisals(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    getList(`${url}appraisalsconfig/getallAppraisals`)
      .then(data => {
        console.log( data);
        setInitializedAppraisalList(data);
      })
      .catch(error => {
        console.error( error);
      });
  }, [url]);
 
  const fetchAppraisalDetails = (id) => {
    getReq(`${url}api/sa/get-all-rows/${id}`)
      .then(response => {
        const appraisalDetails = response?.data;
        navigate('/my-appraisalhistory-list', { state: { appraisalDetails } });})
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div>
      <h1 style={{ textDecoration: 'none' }}>My Appraisal History</h1>
      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Appraisal Initiation ID</th>
            <th>From Year</th>
            <th>To Year</th>
            <th>Reporting Manager</th>
          </tr>
        </thead>
        {console.log(appraisals,"appraisals")}
        <tbody className='table-body'>
        {appraisals?.map((appraisal, index) => (
         <tr key={appraisal.id} onClick={() => fetchAppraisalDetails(appraisal.id)}>
                {console.log(appraisal)}
              <td>{index+1}</td>
              <td>{appraisal?.appraisal_initiation_id}</td>
              <td>{appraisal?.from_year}</td>
              <td>{appraisal?.to_year}</td>
              <td>{appraisal?.reporting_manager}</td>
            </tr>
          )
          )}
          
        </tbody>
      </table>      
      </div>
  );
}

export default MyApprisialHistory;
