import React, { useState, useEffect } from "react";
import {
	Table,
	Button,
	Card,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "../CSS/InitialAppraisal.css";
import Toast, { handleSuccessToast } from "./Toast";
import { getList } from "../Api/commonApi";
import Tables from "./Tables";

const InitializedGoalAppraisalList = () => {
	let url = process.env.REACT_APP_URL;
	const [initializedGoalList, setInitializedGoalList] = useState([]);
	const [roles, setRoles] = useState([]);
	const location = useLocation();

	const getRoleType = (roleID) => {
		const role = roles.find((role) => role.roleID === roleID);
		return role ? role.rolename : "Role not found";
	};
	const getdepartmentstrimed = (department) => {
		const dept = department?.split(",").map(function (item) {
			return item.trim();
		});
		return dept ? dept.map(department => departmentMapping[department] || department).join(", ") : "department not found";
	}

	const departmentMapping = {
		"Finance and Accounts": "Fin. & Acc.",
		"General Admin": "Gen. Admin",
		"Human Resources": "HR",
		"IT Technical": "IT",
		"Sales and Marketing": "Sales & Mktg"
	};



	useEffect(()=>{
		if (location.state !== null) {
            if (location.state.successtoast === true) {
				handleSuccessToast("successfully initiated the appraisals.");
                getList(`${url}goalinitialization/getallGoalInitialization`).then(data=>{setInitializedGoalList(data)});
                getList(`${url}roles/getroles`).then(data=>setRoles(data));
                location.state.successtoast = false;	
            }
			if (location.state.data !== "") {
                setInitializedGoalList((prevData)=>[...prevData, location.state.data] );
            }
        }
	},[location?.state])
    
	useEffect(() => {
		getList(`${url}roles/getroles`).then(data=>setRoles(data));
        getList(`${url}goalinitialization/getallGoalInitialization`).then(data=>{setInitializedGoalList(data)});
	}, []);

	let navigate = useNavigate();

	const handleAddClick = () => {
		navigate("/initialize-goal-form");
	};


	return (
		<>
			<div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
				<h5>Initialized Goal Appraisals</h5>
				<Button className="btn btn-primary ms-auto" onClick={handleAddClick}>
					<i className="bi bi-plus-lg"></i> Add
				</Button>
			</div>

			<Toast></Toast>
			<Tables
				headers={["ID", "Business Unit", "Enabled To", "Department", "From Year", "To Year", "Cut Off Date", "Manager Due Date", "Employee Due Date"]}
				body={initializedGoalList?.length>0 && initializedGoalList?.map(item => ({
                    ID: item?.goalConfigId,
                    "Business Unit": item?.businessUnit,
                    "Enabled To": getRoleType(item?.enableTo),
                    "Department": getdepartmentstrimed(item?.department),
                    "From Year": item?.fromYear?.toString().slice(0, 4),
                    "To Year": item?.toYear?.toString().slice(0, 4),
                    "Cut Off Date": item?.cutOffDate ? item.cutOffDate?.toString().slice(0, 10) : "",
                    "Manager Due Date": item?.managerDueDate ? item.managerDueDate?.toString().slice(0, 10) : "",
                    "Employee Due Date": item?.employeeDueDate ? item.employeeDueDate?.toString().slice(0, 10) : "",
                }))}
				defaultMessage ="No Goals have been initiated."
			/>
		</>
	);
};

export default InitializedGoalAppraisalList;