import React, { useState, useEffect } from "react";
import {
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Button,
    Input,
    Row,
    Col,
    Card,
} from "reactstrap";
import "../CSS/InitialAppraisal.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getReq, postReq } from "../Api/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Toast, { handleErrorToast } from "./Toast";
import { MultiSelect } from "react-multi-select-component";
import { getList, postData } from "../Api/commonApi";
import { Spinner } from "react-bootstrap";

const InitGoalAppraisal = () => {
    let url = process.env.REACT_APP_URL;
    let navigate = useNavigate();
    let select = useSelector((state) => state?.login?.tasks);
    let id = select[0].id;
    const [newCycle, setNewCycle] = useState({
        businessUnit: '',
        department: [],
        fromYear: '',
        toYear: '',
        eligibility: '',
        enableTo: '',
        managerDueDate: '',
        employeeDueDate: '',
        createdBy: '',
        cutOffDate: ''
    });

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);

    const [departments, setdepartments] = useState([]);

    const [businessUnits, setbusinessUnits] = useState([]);

    const [roles, setRoles] = useState([]);

    const [parameters, setparameters] = useState([]);


    useEffect(() => {
        // console.log(businessUnits)
        getList(`${url}employee/distinct/businessunits`).then(data => setbusinessUnits(data));
        getList(`${url}employee/distinct/departments`).then(data => setdepartments(data?.length>0 && data?.map((d) => {
            return { label: d, value: d };
        })));
        getList(`${url}roles/getroles`).then(data => setRoles(data));
        getList(`${url}GetConfig`).then(data => setparameters(data));
    },[] );

    const requiredFields = [
        "fromYear",
        "toYear",
        "businessUnit",
        "eligibility",
        "enableTo",
        "department",
        "managerDueDate",
        "employeeDueDate",
        "createdBy",
        "cutOffDate",
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCycle(prevCycle => ({ ...prevCycle, [name]: value }));
    };

    const handleMInputChange = (selectedOptions) => {
        setNewCycle(prevCycle => ({
            ...prevCycle,
            department: selectedOptions.map(option => ({ label: option.label, value: option.value }))
        }));
    };

    const handleDateChange = (date, field) => {
        if (field === "fromYear") {
          if (date > newCycle.toYear) {
            // Optionally, show an error if the From Year is greater than the To Year
            setErrors((prevErrors) => ({
              ...prevErrors,
              fromYear: "From Year cannot be greater than To Year",
            }));
          } else {
            // Update From Year and reset any existing error
            setNewCycle((prevCycle) => ({ ...prevCycle, fromYear: date }));
            setErrors((prevErrors) => ({ ...prevErrors, fromYear: "" }));
          }
        }
      
        if (field === "toYear") {
          if (date < newCycle.fromYear) {
            // Optionally, show an error if the To Year is less than the From Year
            setErrors((prevErrors) => ({
              ...prevErrors,
              toYear: "To Year cannot be less than From Year",
            }));
          } else {
            // Update To Year and reset any existing error
            setNewCycle((prevCycle) => ({ ...prevCycle, toYear: date }));
            setErrors((prevErrors) => ({ ...prevErrors, toYear: "" }));
          }
        }
      };
      

    const handleSave = () => {
        setLoading(true);
        // newCycle.processStatus = "enabled to employee";
        newCycle.createdBy = id;

        // Validate the form
        const validationErrors = validateForm();
        // console.log(validationErrors)
        if (Object?.keys(validationErrors)?.length > 0) {
            setErrors(validationErrors);
            setLoading(false);
            return;
        }
        setErrors({})

        postData(`${url}goalinitialization/postGoalInitialization`, newCycle).then( data=>{
            setLoading(false);
            setNewCycle({
                fromYear: '',
                toYear: '',
                businessUnit: '',
                eligibility: '',
                enableTo: '',                
                department: [],
                managerDueDate: '',
                employeeDueDate: '',
                cutOffDate: ''
            })
            navigate('/initialized-goal-list', { state: { successtoast: true , data: data } })
        }
            
        );
           
    };


// Function to validate the form
const validateForm = () => {
    const errors = {};
    requiredFields.forEach((field) => {
        // if (!newCycle[field]) {
        //     errors[field] = 'This is required';
        // } else 
        if (field === 'department') {
            if (newCycle[field]?.length === 0) {
                errors[field] = 'This is required';
            }
        } else if (field === 'fromYear') {
            const currentDate = new Date();
           
        } else if (field === 'toYear') {


            
        } else if (field === 'employeeDueDate') {

            const currentDate = new Date();
            if (new Date(newCycle.employeeDueDate) <= currentDate) {
                errors[field] = 'Employee Due Date must be in the future';
            }
        } else if (field === 'managerDueDate') {

            if (new Date(newCycle.managerDueDate) <= new Date(newCycle.employeeDueDate)) {
                errors[field] = 'Manager Due Date must be after Employee Due Date';
            }
        } 
        // else {
        //     if (!newCycle[field]) {
        //         errors[field] = 'This is required';
        //     }
        // }
    });
    return errors;
};


const handleBackClick = () => {
    navigate("/initialized-goal-list");
};
return (
    <>
        <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
            <h5>Initialized Goal Appraisals</h5>
            <Button
                outline
                color="default"
                className="ms-auto"
                onClick={handleBackClick}
            >
                <i class="bi bi-arrow-left"></i> Back
            </Button>
        </div>
        <Card className="pt-5">
            <Form>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label" for="businessUnit">
                                        Business Unit
                                    </Label>
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        id="businessUnit"
                                        type="select"
                                        name="businessUnit"
                                        onChange={handleInputChange}
                                        value={newCycle.businessUnit}
                                        invalid={!!errors["businessUnit"]}
                                        required>
                                        <option value="">Select Business Unit</option>
                                        {businessUnits?.length>0 && businessUnits?.map((businessUnit) => (
                                            <option key={businessUnit} value={businessUnit}>
                                                {businessUnit}
                                            </option>
                                        ))}
                                    </Input>
                                    <FormFeedback>{errors["businessUnit"]}</FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                        <FormGroup controlid="department">
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label">Department</Label>
                                </Col>
                                <Col sm={6}>
                                    <MultiSelect
                                        options={departments.length>0? departments:[] }
                                        value={newCycle.department} 
                                        selected={newCycle.department}
                                        placeholderText="Select Departments"
                                        className={!!errors['department'] ? "is-invalid" : ""}
                                        onChange={handleMInputChange}
                                        labelledBy="department"
                                    />
                                    {!!errors['department'] && <FormFeedback>{errors['department']}</FormFeedback>}
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>


                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label" for="fromYear">
                                        From Year
                                    </Label>
                                </Col>
                                <Col sm={6}>
                                    <DatePicker
                                        id="fromYear"
                                        name="fromYear"
                                        selected={newCycle.fromYear}
                                        onChange={(date) => handleDateChange(date, "fromYear")}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        placeholderText="Select from year"
                                        className={
                                            !!errors["fromYear"]
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        }
                                        required
                                    />
                                    {errors["fromYear"] && (
                                        <p style={{
                                            width: '100%',
                                            fontSize: '0.875em',
                                            marginTop: '0.25rem',
                                            color: 'var(--bs-form-invalid-color)'
                                        }}>{errors["fromYear"]}</p>
                                    )}
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label" for="toYear">
                                        To Year
                                    </Label>
                                </Col>
                                <Col sm={6}>
                                    <DatePicker
                                        id="toYear"
                                        name="toYear"
                                        selected={newCycle.toYear}
                                        onChange={(date) => handleDateChange(date, "toYear")}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        placeholderText="Select to year"
                                        className={
                                            !!errors["toYear"]
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        }
                                        required
                                    />
                                    {errors["toYear"] && (
                                        <p style={{
                                            width: '100%',
                                            marginTop: '0.25rem',
                                            fontSize: '0.875em',
                                            color: 'var(--bs-form-invalid-color)'
                                        }}>{errors["toYear"]}</p>
                                    )}
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label" for="eligibility">
                                        Eligibility
                                    </Label>
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        id="eligibility"
                                        type="select"
                                        name="eligibility"
                                        onChange={handleInputChange}
                                        value={newCycle.eligibility}
                                        invalid={!!errors["eligibility"]}
                                        required>
                                        <option value="">Select Eligibility</option>
                                        <option value="permanent">Permanent</option>
                                        <option value="temporary">Temporary</option>
                                    </Input>
                                    <FormFeedback>{errors["eligibility"]}</FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label" for="enableTo">
                                        Enable To
                                    </Label>
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        id="enableTo"
                                        type="select"
                                        name="enableTo"
                                        onChange={handleInputChange}
                                        value={newCycle.enableTo}
                                        invalid={!!errors["enableTo"]}
                                        required>
                                        <option value="">Select Enable To</option>
                                        {roles?.length>0 && roles?.map((role) => (
                                            <option key={role.roleID} value={role.roleID}>
                                                {role.rolename}
                                            </option>
                                        ))}
                                    </Input>
                                    <FormFeedback>{errors["enableTo"]}</FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>  

                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label" id="cutOffDate">
                                        Employee Cut off Date
                                    </Label>
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        id="cutOffDate"
                                        type="date"
                                        name="cutOffDate"
                                        onChange={handleInputChange}
                                        value={newCycle.cutOffDate}
                                        invalid={!!errors["cutOffDate"]}
                                        required
                                    />
                                    <FormFeedback>{errors["cutOffDate"]}</FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label" id="employeeDueDate">
                                        Employee Due Date
                                    </Label>
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        id="employeeDueDate"
                                        type="date"
                                        name="employeeDueDate"
                                        onChange={handleInputChange}
                                        value={newCycle.employeeDueDate}
                                        invalid={!!errors["employeeDueDate"]}
                                        required
                                    />
                                    <FormFeedback>{errors["employeeDueDate"]}</FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="form-label" for="managerDueDate">
                                        Manager Due Date
                                    </Label>
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        id="managerDueDate"
                                        type="date"
                                        name="managerDueDate"
                                        onChange={handleInputChange}
                                        value={newCycle.managerDueDate}
                                        invalid={!!errors["managerDueDate"]}
                                        required
                                    />
                                    <FormFeedback>{errors["managerDueDate"]}</FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>

                </Row>
                {/* <Row className="mt-3">
                    <Col xs={12} md={12} className="savebutton">
                        <Button onClick={handleSave}>Save Configuration</Button>
                        {loading && <Spinner animation="border" role="status" /> }
                    </Col>
                </Row> */}
                <div className="position-relative">
                    {loading && (
                        <div className="overlay">
                            <Spinner animation="border" role="status" className="spinner" />
                        </div>
                    )}
                    <Row className="mt-3">
                        <Col xs={12} md={12} className="savebutton">
                            <Button onClick={handleSave} disabled={loading}>
                                Save Configuration
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
            <Toast />
        </Card>
    </>
);
};

export default InitGoalAppraisal;